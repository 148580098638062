import React, { useState,useEffect,useContext } from 'react';
import { useTranslate } from 'react-redux-multilingual'
import {CardPerso,Loading,FormatedPrice} from '@gull'
import axios from 'axios';
import swal from "sweetalert2";
import { IsAuthContext } from 'app/App';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChartLine } from '@fortawesome/free-solid-svg-icons'
import FactureNonAcquitteList from './DashboardComp/FactureNonAcquitteList'
import RappelList from './DashboardComp/RappelList'
import RecurrenceList from './DashboardComp/RecurrenceList'

const useLoad = (authParam) =>{
    const t = useTranslate();
    const [loading,setLoading]=useState(true)
    const [company,setCompany] = useState(false) 
    const [allDocumentsNonAcquitte,setAllDocumentsNonAcquitte] = useState([]) 
    const [ca,setCa]=useState(false) 
    const [urlCompanyId,setUrlCompanyId]=useState('') 
    const [reloadData,setReloadData]=useState(false) 
    const [allAlert,setAllAlert]=useState([]) 
    const [allRecurrence,setAllRecurrence]=useState([])
    const [autorisedCallApi,setAutorisedCallApi]=useState({
        company:true,
        ca:false,
        facture:false,
        alert:false,
        recurrence:false
    }) 

    useEffect(function () {
        (async function() {
            if(autorisedCallApi.company){
                const response = await axios.get(process.env.REACT_APP_API_URL+"api/company/"+authParam.company.id
                ).then((response) => {
                    setCompany(response.data)
                }).catch((error) => {
                    if(error=='end_session') return;
                    let errorName = (error.response==undefined) ? 'unknowError' : (error.response.status==403) ? 'forbiddenGeneral' : 'unknowError'
                    swal.fire(t(errorName), "", "error");
                    setCompany({})
                })
                setAutorisedCallApi({...autorisedCallApi,company:false,facture:true})
            }
        })()
    }, [autorisedCallApi.company])

    useEffect(function () {
        (async function() {
            if(autorisedCallApi.facture){
                const response = await axios.get(process.env.REACT_APP_API_URL+"api/facture/invoice/get_all_non_acquitte"
                ).then((response) => {
                    if(!response.data.valid){
                        let interms='';
                        var keys = Object.keys(response.data.errors);
                        for(var i = 0; i < keys.length; i++){
                            interms+=response.data.errors[keys[i]].join(',')+','
                        }
                        interms=interms.split(',')
                        let messageError='';
                        interms.forEach(interm=>messageError+=t(interm)+'. ')
                        messageError=messageError.replace('undefined','');
                        swal.fire(messageError, "", "error");
                    }
                    else{   
                        setAllDocumentsNonAcquitte(response.data.datas)
                    }
                }).catch((error) => {
                    if(error=='end_session') return;
                    let errorName = (error.response==undefined) ? 'unknowError' : (error.response.status==403) ? 'forbiddenGeneral' : 'unknowError'
                    swal.fire(t(errorName), "", "error");
                })
                setAutorisedCallApi({...autorisedCallApi,facture:false,alert:true})
            }
        })()
    }, [autorisedCallApi.facture])

    useEffect(function () {
        (async function() {
            if(autorisedCallApi.alert){
                const response = await axios.get(process.env.REACT_APP_API_URL+"api/facture/alert/current_alert"
                ).then((response) => {
                    if(!response.data.valid){
                        let interms='';
                        var keys = Object.keys(response.data.errors);
                        for(var i = 0; i < keys.length; i++){
                            interms+=response.data.errors[keys[i]].join(',')+','
                        }
                        interms=interms.split(',')
                        let messageError='';
                        interms.forEach(interm=>messageError+=t(interm)+'. ')
                        messageError=messageError.replace('undefined','');
                        swal.fire(messageError, "", "error");
                    }
                    else{   
                        setAllAlert(response.data.datas)
                    }
                }).catch((error) => {
                    if(error=='end_session') return;
                    let errorName = (error.response==undefined) ? 'unknowError' : (error.response.status==403) ? 'forbiddenGeneral' : 'unknowError'
                    swal.fire(t(errorName), "", "error");
                })
                setAutorisedCallApi({...autorisedCallApi,alert:false,recurrence:true})
            }
        })()
    }, [autorisedCallApi.alert])

    useEffect(function () {
        (async function() {
            if(autorisedCallApi.recurrence){
                const response = await axios.get(process.env.REACT_APP_API_URL+"api/facture/model/current_recurrence"
                ).then((response) => {
                    if(!response.data.valid){
                        let interms='';
                        var keys = Object.keys(response.data.errors);
                        for(var i = 0; i < keys.length; i++){
                            interms+=response.data.errors[keys[i]].join(',')+','
                        }
                        interms=interms.split(',')
                        let messageError='';
                        interms.forEach(interm=>messageError+=t(interm)+'. ')
                        messageError=messageError.replace('undefined','');
                        swal.fire(messageError, "", "error");
                    }
                    else{   
                        setAllRecurrence(response.data.datas)
                        setLoading(false)
                    }
                }).catch((error) => {
                    if(error=='end_session') return;
                    let errorName = (error.response==undefined) ? 'unknowError' : (error.response.status==403) ? 'forbiddenGeneral' : 'unknowError'
                    swal.fire(t(errorName), "", "error");
                })
                setLoading(false)
                setAutorisedCallApi({...autorisedCallApi,recurrence:false,ca:true})
            }
        })()
    }, [autorisedCallApi.recurrence])

    useEffect(function () {
        (async function() {
            if(autorisedCallApi.ca){
                setReloadData(true)
                const response = await axios.get(process.env.REACT_APP_API_URL+"api/facture/report/current_and_previous_month"+urlCompanyId
                ).then((response) => {
                    if(!response.data.valid){
                        let interms='';
                        var keys = Object.keys(response.data.errors);
                        for(var i = 0; i < keys.length; i++){
                            interms+=response.data.errors[keys[i]].join(',')+','
                        }
                        interms=interms.split(',')
                        let messageError='';
                        interms.forEach(interm=>messageError+=t(interm)+'. ')
                        messageError=messageError.replace('undefined','');
                        swal.fire(messageError, "", "error");
                        setCa({
                            current:{},
                            previous:{}
                        })
                    }
                    else{   
                        setCa({
                            current:response.data.current_month,
                            previous:response.data.previous_month
                        })
                    }
                }).catch((error) => {
                    if(error=='end_session') return;
                    let errorName = (error.response==undefined) ? 'unknowError' : (error.response.status==403) ? 'forbiddenGeneral' : 'unknowError'
                    swal.fire(t(errorName), "", "error");
                    setCa({
                        current:{},
                        previous:{}
                    })
                })
                setReloadData(false)
            }
        })()
    }, [autorisedCallApi.ca,urlCompanyId])

    return [loading,allDocumentsNonAcquitte,ca,company,setUrlCompanyId,urlCompanyId,reloadData,allAlert,setAllAlert,allRecurrence,setAllRecurrence]
}

const DashboardBilling = () =>{
    const t = useTranslate();
    const { authParam } = useContext(IsAuthContext);
    const [loading,allDocumentsNonAcquitte,ca,company,setUrlCompanyId,urlCompanyId,reloadData,allAlert,setAllAlert,allRecurrence,setAllRecurrence]=useLoad(authParam)

    let devise = authParam.company.devise;
    let digit = (authParam.company.devise === "XPF") ? 0 : 2;

    const handleChange = (e) =>{
        setUrlCompanyId(e.target.value)
    }
    
    if(loading){
        return(<Loading></Loading>)
    }

    return (
        <div>
            <div className='row'>
                <div className='col-6 form-group'>
                    <label>{t('companyName')}</label>
                    <select className='form-control' value={urlCompanyId} onChange={(e)=>handleChange(e)}>
                        <option value={''}>{company.name}</option>                            
                        {Object.keys(company).length !=0 && company.other_company.length != 0 &&
                            company.other_company.map(value=>{
                                return(
                                    <option key={value.id} value={'/'+value.id}>{value.name}</option>
                                )
                            })
                        }
                    </select>
                </div>
            </div>

            <div className='row'>
                {allAlert.length!=0 &&
                    <RappelList
                        allAlert={allAlert}
                        setAllAlert={setAllAlert}
                    /> 
                }
            </div>

            <div className='row'>
                {allRecurrence.length!=0 &&
                    <RecurrenceList
                        allRecurrence={allRecurrence}
                        setAllRecurrence={setAllRecurrence}
                    /> 
                }
            </div>
            
            <div className='row'>
                <div className="col-12 col-md-6 d-flex">
                    <CardPerso 
                        header={t('presentMonthTurnover')} 
                        bgBody='true' 
                        bgBodyColor='#d9fcd2'
                        saClass='fs-3 text-center'
                        
                    >
                        {(reloadData) ? 
                            <div className="spinner-border spinner-border-sm"></div>
                        :
                            <div className='text-center fw-bold'>
                                <FontAwesomeIcon className='fs-1 mb-2' icon={faChartLine} />
                                <div className='fs-2'>
                                    <FormatedPrice langRegion={'fr-FR'} devise={devise} digit={digit} priceToFormat={(ca && Object.keys(ca.current).length!= 0) ? ca.current.total_ttc : 0} />
                                    <span>{' '+t('taxesIncluded')}</span>
                                </div>
                                <div className='fs-4'>
                                    <span>{'( '}</span>
                                    <FormatedPrice langRegion={'fr-FR'} devise={devise} digit={digit} priceToFormat={(ca && Object.keys(ca.current).length!= 0) ? ca.current.total_ht : 0} />
                                    <span>{' '+t('taxesExcluded')+' )'}</span>
                                </div>
                            </div>}
                    </CardPerso>
                </div>
                <div className="col-12 col-md-6 d-flex">
                    <CardPerso 
                        header={t('previousMonthTurnover')}
                        bgBody='true' 
                        bgBodyColor='#3c8dbc' 
                        textColor='#ffffff'
                        saClass='fs-3 text-center'
                    >
                        {(reloadData) ? 
                            <div className="spinner-border spinner-border-sm"></div>
                        :
                            <div className='text-center fw-bold'>
                                <FontAwesomeIcon className='fs-1 mb-2' icon={faChartLine} />
                                <div className='fs-2'>
                                    <FormatedPrice langRegion={'fr-FR'} devise={devise} digit={digit} priceToFormat={(ca && Object.keys(ca.previous).length!= 0) ? ca.previous.total_ttc : 0} />
                                    <span>{' '+t('taxesIncluded')}</span>
                                </div>
                                <div className='fs-4'>
                                    <span>{'( '}</span>
                                    <FormatedPrice langRegion={'fr-FR'} devise={devise} digit={digit} priceToFormat={(ca && Object.keys(ca.previous).length!= 0) ? ca.previous.total_ht : 0} />
                                    <span>{' '+t('taxesExcluded')+' )'}</span>
                                </div>
                            </div>}
                    </CardPerso>
                </div>
            </div>
            <CardPerso header={t('unpaidInvoice')}>
                <FactureNonAcquitteList
                    allDocumentsNonAcquitte={allDocumentsNonAcquitte}
                    devise={devise} 
                    digit={digit}
                    reloadData={reloadData}
                />
            </CardPerso>
        </div>
    )
};

export default DashboardBilling;