import React, { useState,useEffect } from 'react';
import { useTranslate } from 'react-redux-multilingual'
import axios from 'axios';

const AlertDocument = (props) =>{
    const t = useTranslate();
    const [valueInput,setValueInput]=useState({
        date:'',
        text:''
    }) 
    const [success,setSuccess]=useState(false) 
    const [apiError,setApiError]=useState(false) 

    useEffect(function () {
        if(props.allInfos.alert_date){
            let dateSplit = props.allInfos.alert_date.split(' ')
            let obj = {
                date:dateSplit[0],
            }
            if(props.allInfos.alert_texte!=null) obj['text']=props.allInfos.alert_texte

            setValueInput(obj)
        }
    }, [props.allInfos])

    const handleChange = (e,name) =>{
        setValueInput({...valueInput,[name]:e.target.value})
    }

    const createModel = async (param) =>{
        props.setValidButton(true)
        setApiError(false)

        let obj = {}

        if(param=='validate'){//si on créé un rappel ou on l'edit, on lui passe les parametres alert_date et alert_texte
            //Dans le cas d'une annulation on passe un objet vide 
            obj['alert_date']=valueInput.date
            obj['alert_texte']=valueInput.text
        }

        const response = await axios.post(process.env.REACT_APP_API_URL+"api/facture/alert/create_or_update/"+props.id, obj
        ).then((response) => {
            if(!response.data.valid){
                let interms='';
                var keys = Object.keys(response.data.errors);
                for(var i = 0; i < keys.length; i++){
                    interms+=response.data.errors[keys[i]].join(',')+','
                }
                interms=interms.split(',')
                let messageError='';
                interms.forEach(interm=>messageError+=t(interm)+'. ')
                messageError=messageError.replace('undefined','');
                setApiError(messageError);
            }
            else{   
                setSuccess(true)
                if(props.from=='dashboard'){
                    let allReminders = props.allAlert.map(value=>value.id)
                    let indexToEdit = allReminders.indexOf(props.id)
                    let newData = props.allAlert.filter(value=>value.id!=props.id)

                    newData.splice(indexToEdit,0, response.data.data)

                    props.setAllInfos(newData)
                }else if(props.from=='detailDoc'){
                    props.setAllInfos(response.data.data)
                }
                if(param=='cancel'){
                    //Dans le cas d'une annulation on vide les champs 
                    setValueInput({
                        date:'',
                        text:''
                    })
                }

            }
        }).catch((error) => {
            if(error=='end_session') return;
            setApiError(t('unknowError'));
        })
        props.setValidButton(false)
        setTimeout(() => {
            setSuccess(false)
        }, 1500);
    }
    
    const handleClick = (param) =>{
        if(valueInput.date == ''){
            props.setErrors(true)

        }else{
            createModel(param)
        }
    }
    
    return (
        <div>
            <div className="form-group mb-3">
                <label htmlFor="titre">{t('date_alert')}</label>
                <input type="date" value={valueInput.date} className='form-control' onChange={(e)=>handleChange(e,'date')}/>
                {(props.errors && valueInput.date == '') &&
                    <div className="text-danger mt-1 ml-2">
                        {t('fieldRequired')}
                    </div>
                }
            </div>

            <div className="form-group mb-3">
                <label htmlFor="titre">{t('alert_texte')}</label>
                <textarea value={valueInput.text} className='form-control' onChange={(e)=>handleChange(e,'text')} cols="20" rows="10"></textarea>
            </div>

            {(success) ? <div className="alert alert-success mt-3">{t('successOperation')}</div> : false}
            {(apiError) ? <div className="alert alert-danger mt-3">{apiError}</div> : false}
            <div>
                <button disabled={props.validButton} className="btn btn-primary mt-2" onClick={()=>handleClick('validate')}>
                    {props.validButton ? <span><span>&nbsp;</span><div className="spinner-border spinner-border-sm"></div></span> : t('btnValidate')}
                </button>&nbsp;&nbsp;
                {(props.allInfos.alert_date && props.from!='dashboard') ?
                    <button disabled={props.validButton} className='btn btn-danger mt-2' onClick={()=>handleClick('cancel')}>
                        {props.validButton ? <span><span>&nbsp;</span><div className="spinner-border spinner-border-sm"></div></span> : t('cancelReminder')}
                    </button>
                :
                    false
                }
            </div>
        </div>
    )
};

export default AlertDocument;