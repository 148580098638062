import React, {useState,useEffect,useContext} from "react";
import { IsAuthContext } from 'app/App';
import { useTranslate } from 'react-redux-multilingual'
import { useForm } from "react-hook-form";
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBirthdayCake } from '@fortawesome/free-solid-svg-icons'

const useLoadCompany = (clientid,setValue) => {
    const { authParam } = useContext(IsAuthContext);
    const [loading,setLoading]=useState(true)
    const [error,setError]=useState(false)
    const [clientForm,setClientForm]=useState(false)
    const [company,setCompany]=useState(false)
    const [exonerationTaxe,setExonerationTaxe]=useState(false) 

    useEffect(function () {
        (async function() {
            if(!clientid){
                const response = await axios.get(process.env.REACT_APP_API_URL+"api/company/"+authParam.company.id
                ).then((response) => {
                    setCompany(response.data)
                    setLoading(false)
                    setClientForm(response.data.clientform)
                }).catch((error) => {
                    if(error=='end_session') return;
                    setLoading(false)
                    setError(true)
                })
            }
            else{
                const response = await axios.get(process.env.REACT_APP_API_URL+"api/client/"+clientid
                ).then((response) => {
                    setCompany(response.data.company)
                    setLoading(false)
                    setClientForm(response.data.company.clientform)
                    setValue('name',response.data.name)
                    setValue('firstname',response.data.firstname)
                    setValue('email',response.data.email)
                    setValue('tel',response.data.tel)
                    setValue('type',response.data.type)
                    if(response.data.exoneration_tgc!=0){
                        setExonerationTaxe(true)
                    }else{
                        setExonerationTaxe(false)
                    }
                    
                    if(response.data.infos){
                        let keys = Object.keys(response.data.infos);
                        for(var i = 0; i < keys.length; i++){
                            setValue(keys[i],response.data.infos[keys[i]])
                        }
                    }
                }).catch((error) => {
                    if(error=='end_session') return;
                    setLoading(false)
                    setError(true)
                })
            }
        })()
    }, [])
    return [loading,error,clientForm,company,exonerationTaxe,setExonerationTaxe]
}

const ClientForm = (props) => {
    const t = useTranslate()
    const { register, formState: { errors }, handleSubmit, setValue } = useForm();
    const [loading,error,clientForm,company,exonerationTaxe,setExonerationTaxe]=useLoadCompany(props.clientid,setValue);
    const [validButton,setValidButton] = useState(false)
    const [apiError,setApiError] = useState(false)
    const [maxPhoneLength, setMaxPhoneLength] = useState({
        tel: false,
        autretel:false
    })
    

    const onSubmit = async (data) => {
        setValidButton(true)
        setApiError(false)

        let obj = {}
        Object.entries(data).map(([key,value])=>{
            obj[key]=value
        })
        obj['exoneration']=exonerationTaxe

        const response  = await axios.post(process.env.REACT_APP_API_URL+"api/client", obj
        ).then((response) => {
            if(!response.data.valid){
                let interms='';
                var keys = Object.keys(response.data.errors);
                for(var i = 0; i < keys.length; i++){
                    interms+=response.data.errors[keys[i]].join(',')+','
                }
                interms=interms.split(',')
                let messageError='';
                interms.forEach(interm=>messageError+=t(interm)+'. ')
                messageError=messageError.replace('undefined','');
                setApiError(messageError)
            }
            else{
                if(props.setListSearchIdClient){
                    props.setListSearchIdClient({[response.data.data.name+' '+response.data.data.firstname+' - '+response.data.data.email+' - '+response.data.data.tel]:response.data.data.id})
                }
                if( props.setClientNameInput){
                    props.setClientNameInput(response.data.data.name+' '+response.data.data.firstname+' - '+response.data.data.email+' - '+response.data.data.tel)
                }
                if(props.setInvalidInput){
                    props.setInvalidInput({...props.invalidInput,searchClient:false})
                }
                if(props.setValue){
                    props.setValue(response.data.data)
                }
                if(props.from == 'barLeft'){
                    if(props.setClientId){
                        props.setClientId(response.data.data.id)
                    }
                    if(props.cookies && props.cookies.current_ticket){
                        props.updateCurentTicket('searchClient',response.data.data.id)
                    }
                    if(props.otherModule && props.otherModule=='erp'){
                        props.updateCurentTicket('searchClient',response.data.data.id)
                    }
                } 
                if(props.clientid){
                    if(props.closeModal) props.closeModal('edit') 
                }else{
                    if(props.closeModal) props.closeModal('add') 
                }   
                if(props.module && props.module=='facturation'){
                    if(props.setClientDetail) props.setClientDetail(response.data.data)
                }
                if(props.module && props.module=='facturationEdit'){
                    if(props.editPlusFacture) props.editPlusFacture({client_id:response.data.data.id})
                }
                if(props.callBackClientForm) props.callBackClientForm(response.data.data) //Si cette fonction est passée en props, elle exécutera toutes les actions apres création du client 
            }
        }).catch((error) => {
            if(error=='end_session') return;
            if(error.response==undefined){
                setApiError(t('unknowError'))
            }
            else if(error.response.status==403){
                setApiError(t('forbiddenGeneral'))
            }
            else {
                setApiError(t('unknowError'))
            }
        })
        setValidButton(false)
    }

    const handleChange = (e,name) => {
        setValue(name,e.target.value.replace(/\D/g,''));
    }

    const handleChangePhonenumber = (e,field) => {
        setMaxPhoneLength({...maxPhoneLength,[field]: e.target.value.length > 6})
        setValue(field,e.target.value.replace(/\D/g,'').substring(0,6));
    }

    if(loading){
        return (
            <div className="col-12 text-center">
                <span><span>&nbsp;</span><div className="spinner-border spinner-border-sm mt-2"></div></span>
            </div>
        );
    }
    if(error){
        return (
            <div className="col-12 text-center">
                <p className="text-danger"><strong>{t('unknowError')}</strong></p>
            </div>
        )
    }

    return(<>
        {(!clientForm) ?
            <div className="col-12 text-center">
                <p className="text-danger">{t('noFormClient')}</p>
            </div>
            :
            <div className="col-12">
                <form onSubmit={handleSubmit(onSubmit)}>
                    {(props.clientid) &&
                        <input type="hidden" defaultValue={props.clientid} {...register('clientid')} />
                    }
                    <div className="row">
                        <div className="col-6">
                            <div className="row">
                                {(clientForm.civilite[0]) && <>
                                    <div className="form-group mb-2 input-group">
                                        <div className="row">
                                            <div className="col-4">
                                                <label>{t('civilite')}{(clientForm.civilite[1]) && '*'} :</label>
                                            </div>
                                            <div className="col-4">
                                                <div className="form-check">
                                                    <input type="radio" className="form-check-input" value="femme" {...register('civilite',{required: clientForm.civilite[1]})}/>
                                                    <label className="form-check-label">{t('femme')}</label>
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <div className="form-check">
                                                    <input type="radio" className="form-check-input" value="homme" {...register('civilite',{required: clientForm.civilite[1]})}/>
                                                    <label className="form-check-label">{t('homme')}</label>
                                                </div>
                                            </div>
                                            {errors.civilite?.type === 'required' && (
                                            <div className="text-danger mt-1 ml-2">
                                                {t('civiliteRequired')}
                                            </div>
                                            )}
                                        </div>
                                    </div>
                                </>}
                            </div>
                            {(props.module && props.module=='facturation' || props.module && props.module=='facturationEdit') &&
                                <div className="row">
                                    <div className="form-group mb-2 input-group">
                                        <div className="form-check form-switch">
                                            <input className="form-check-input" disabled={props.module && props.module=='facturationEdit'} checked={exonerationTaxe} type="checkbox" role="switch" onChange={() => setExonerationTaxe(!exonerationTaxe)}/>
                                            <label className="form-check-label" >{t('exonerateFromTaxes')}</label>
                                        </div>
                                    </div>
                                </div>
                            }
                            <div className="row">
                                <div className="form-group mb-2">
                                    <label>{t('tableName')+' '+t('or').toLowerCase()+' '+t('company').toLowerCase()+'*'}</label>
                                    <input type="text" placeholder={t('tableName')+' '+t('or').toLowerCase()+' '+t('company').toLowerCase()+'...*'} className="form-control" {...register('name',{required: true,minLength:2,maxLength:191})}/>
                                    {errors.name?.type === 'required' && (
                                    <div className="text-danger mt-1 ml-2">
                                        {t('fieldRequired')}
                                    </div>
                                    )}
                                    {errors.name?.type === 'minLength' && (
                                    <div className="text-danger mt-1 ml-2">
                                        {t('nameMin2')}
                                    </div>
                                    )}
                                    {errors.name?.type === 'maxLength' && (
                                    <div className="text-danger mt-1 ml-2">
                                        {t('nameMax191')}
                                    </div>
                                    )}
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group mb-2">
                                    <label>{t('tableFirstName')}{(clientForm.firstname && clientForm.firstname[1]) ? '*' : ''} :</label>
                                    <input type="text" placeholder={t('tableFirstName')+'...'} className="form-control" {...register('firstname',{required: (clientForm.firstname && clientForm.firstname[1]) ? true : false,minLength:2,maxLength:191})}/>
                                    {errors.firstname?.type === 'required' && (
                                    <div className="text-danger mt-1 ml-2">
                                        {t('firstnameRequired')}
                                    </div>
                                    )}
                                    {errors.firstname?.type === 'minLength' && (
                                    <div className="text-danger mt-1 ml-2">
                                        {t('firstnameMin2')}
                                    </div>
                                    )}
                                    {errors.firstname?.type === 'maxLength' && (
                                    <div className="text-danger mt-1 ml-2">
                                        {t('firstnameMax191')}
                                    </div>
                                    )}
                                </div>
                            </div>
                            <div className="row">
                                {(clientForm.datedenaissance[0]) &&
                                    <div className="form-group mb-2 input-group">
                                        <div className="input-group ">
                                            <div className="input-group-prepend">
                                                <div className="input-group-text rounded-0" style={{height:'100%'}}><FontAwesomeIcon icon={faBirthdayCake} /></div>
                                            </div>
                                            <input type="date" placeholder={(clientForm.datedenaissance[1]) ? t('dateDeNaissance')+'...*' : t('dateDeNaissance')+'...'} className="form-control" {...register('datedenaissance',{required: clientForm.datedenaissance[1]})}/>
                                        </div>
                                        {errors.datedenaissance?.type === 'required' && (
                                        <div className="text-danger mt-1 ml-2">
                                            {t('dateDeNaissanceRequired')}
                                        </div>
                                        )}
                                    </div>
                                }
                            </div>
                            <div className="row">
                                <div className="form-group mb-2">
                                    <label>Email{(clientForm.email && clientForm.email[1]) ? '*' : ''} :</label>
                                    <input type="email" placeholder="Email..." className="form-control" {...register('email',{required: (clientForm.email && clientForm.email[1]) ? true : false})}/>
                                    {errors.email?.type === 'required' && (
                                    <div className="text-danger mt-1 ml-2">
                                        {t('emailRequired')}
                                    </div>
                                    )}
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group mb-2 input-group">
                                    <div className="input-group-prepend">
                                        <div className="input-group-text rounded-0" style={{height:'100%'}}>+687{(clientForm.tel && clientForm.tel[1]) ? '*' : ''}</div>
                                    </div>
                                    <input type="text" placeholder={t('tablePhone')+'...'} className="form-control" {...register('tel', {required: (clientForm.tel && clientForm.tel[1]) ? true : false, onChange :(e) => handleChangePhonenumber(e,'tel')})} />
                                    {errors.tel?.type === 'required' && (
                                    <div className="text-danger mt-1 ml-2">
                                        {t('fieldRequired')}
                                    </div>
                                    )}
                                    {(maxPhoneLength.tel) && 
                                        <div className="text-danger mt-1 ml-2">
                                            {t('maxPhoneLength6')}
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="row">
                                {(clientForm.autretel && clientForm.autretel[0]) &&
                                    <div className="form-group mb-2 input-group">
                                        <div className="input-group-prepend">
                                            <div className="input-group-text rounded-0" style={{height:'100%'}}>+687</div>
                                        </div>
                                        <input type="text" placeholder={(clientForm.autretel[1]) ? t('tableOtherPhone')+'...*' : t('tableOtherPhone')+'...'} className="form-control" {...register('autretel',{required: clientForm.autretel[1], onChange :(e) => handleChangePhonenumber(e,'autretel')})} />
                                        {errors.autretel?.type === 'required' && (
                                        <div className="text-danger mt-1 ml-2">
                                            {t('autretelRequired')}
                                        </div>
                                        )}
                                    </div>
                                }
                                {(maxPhoneLength.autretel) && 
                                    <div className="text-danger mt-1 ml-2">
                                        {t('maxPhoneLength6')}
                                    </div>
                                }
                            </div>
                            <div className="row">
                                {(clientForm.newsletter[0]) &&
                                    <div className="col-12 mt-2">
                                        <label className="checkbox checkbox-outline-primary">
                                            <input type="checkbox" {...register('newsletter',{required: clientForm.newsletter[1]})} />
                                            <span className="checkmark"></span>
                                            {t('inscriptionNewsletter')}{(clientForm.newsletter[1]) && '*'}
                                        </label>
                                        {errors.newsletter?.type === 'required' && (
                                        <div className="text-danger mt-1 ml-2">
                                            {t('newsletterRequired')}
                                        </div>
                                        )}
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="col">
                                <div className='form-group mb-2 input-group'>
                                    <div className="input-group-prepend">
                                        <div className="input-group-text rounded-0" style={{height:'100%'}}>Type</div>
                                    </div>
                                    <select className="form-control" type="text" {...register('type',{required: true})}>
                                        <option value="Local">Local</option>
                                        {(company.clienttype!=null) ? 
                                            company.clienttype.map((clienttype,index)=>
                                                <option key={index} value={clienttype}>{clienttype}</option>
                                            )
                                            :
                                            false
                                        }
                                    </select>
                                </div>
                                
                                {errors.type?.type === 'required' && (
                                <div className="text-danger mt-1 ml-2">
                                    {t('typeRequired')}
                                </div>
                                )}
                            </div>
                            {(clientForm.adresse[0]) &&
                                <div className="col">
                                    <div className="form-group mb-2">
                                        <label>{(clientForm.adresse[1]) ? t('labelAdress')+'*' : t('labelAdress')}</label>
                                        <textarea rows={5} placeholder={(clientForm.adresse[1]) ? t('labelAdress')+'...*' : t('labelAdress')+'...'} className="form-control" {...register('adresse',{required: clientForm.adresse[1]})}/>
                                        {errors.adresse?.type === 'required' && (
                                        <div className="text-danger mt-1 ml-2">
                                            {t('adresseRequired')}
                                        </div>
                                        )}
                                    </div>
                                </div>
                            }
                            {(clientForm.quartier && clientForm.quartier[0]) &&
                                <div className="col">
                                    <div className="form-group mb-2">
                                        <label>{(clientForm.quartier[1]) ? t('quartier')+'*' : t('quartier')}</label>
                                        <input type="text" placeholder={(clientForm.quartier[1]) ? t('quartier')+'...*' : t('quartier')+'...'} className="form-control" {...register('quartier',{required: clientForm.quartier[1]})}/>
                                        {errors.quartier?.type === 'required' && (
                                        <div className="text-danger mt-1 ml-2">
                                            {t('quartierRequired')}
                                        </div>
                                        )}
                                    </div>
                                </div>
                            }
                            {(clientForm.ville && clientForm.ville[0]) &&
                                <div className="col">
                                    <div className="form-group mb-2">
                                        <label>{(clientForm.ville[1]) ? t('labelVille')+'*' : t('labelVille')}</label>
                                        <input type="text" placeholder={(clientForm.ville[1]) ? t('labelVille')+'...*' : t('labelVille')+'...'} className="form-control" {...register('ville',{required: clientForm.ville[1]})}/>
                                        {errors.ville?.type === 'required' && (
                                        <div className="text-danger mt-1 ml-2">
                                            {t('villeRequired')}
                                        </div>
                                        )}
                                    </div>
                                </div>
                            }
                            {(clientForm.pays && clientForm.pays[0]) &&
                                <div className="col">
                                    <div className="form-group mb-2">
                                        <label>{(clientForm.pays[1]) ? t('labelPays')+'*' : t('labelPays')}</label>
                                        <input type="text" placeholder={(clientForm.pays[1]) ? t('labelPays')+'...*' : t('labelPays')+'...'} className="form-control" {...register('pays',{required: clientForm.pays[1]})}/>
                                        {errors.pays?.type === 'required' && (
                                        <div className="text-danger mt-1 ml-2">
                                            {t('paysRequired')}
                                        </div>
                                        )}
                                    </div>
                                </div>
                            }
                            {(clientForm.codepostal && clientForm.codepostal[0]) &&
                                <div className="col">
                                    <div className="form-group mb-2">
                                        <label>{(clientForm.codepostal[1]) ? t('labelCodePostal')+'*' : t('labelCodePostal')}</label>
                                        <input type="text" placeholder={(clientForm.codepostal[1]) ? t('labelCodePostal')+'...*' : t('labelCodePostal')+'...'} className="form-control" {...register('codepostal',{required: clientForm.codepostal[1], onChange :(e) => handleChangePhonenumber(e,'codepostal')})}/>
                                        {errors.codepostal?.type === 'required' && (
                                        <div className="text-danger mt-1 ml-2">
                                            {t('codePostalRequired')}
                                        </div>
                                        )}
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    {(apiError) ? <div className="alert alert-danger mt-3" dangerouslySetInnerHTML={{ __html: apiError }}></div> : false}
                    <button disabled={validButton} className="btn btn-primary mt-3">
                        {validButton ? <span><span>&nbsp;</span><div className="spinner-border spinner-border-sm"></div></span> : t('btnValidate')}
                    </button>
                </form> 
            </div>  
        }
    </>)
}

export default ClientForm