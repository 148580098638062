import React, { useState,useEffect } from 'react';
import { useTranslate } from 'react-redux-multilingual'
import {ColumDataTableReorganize,ProfilActionButton,FormatedPrice} from '@gull'
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { useCookies} from 'react-cookie';
import {scrollXDataTable} from 'app/scrollXDataTable';
import Moment from 'react-moment';

const CustomDataTable = (props) => {
    const t = useTranslate();
    
    useEffect(() => {
        scrollXDataTable(window.screen.width,props.columns.length)
    }, [window.screen.width,props.columns.length]);
    
    return (
        <BootstrapTable
            {...props.propst.baseProps}
            bootstrap4
            pagination={paginationFactory(props.paginationOptions)}
            noDataIndication={t('tableNoData')}
            defaultSortDirection={props.defaultSortDirection}
        />
    );
};

const FactureNonAcquitteList = (props) =>{
    const t = useTranslate();
    let { SearchBar } = Search;

    const [perPage,setPerPage]=useState(50)
    const [cookies, setCookie] = useCookies(['dashboard_billing_table_reorganize']);
    const [columnOrganize,setColumnOrganize]=useState([])
    const [showColumnOrganize,setShowColumnOrganize]=useState(false) 

    let current_date = new Date()
    let timestampdate = current_date.setDate(current_date.getDate() + 90)
    let expire_date = new Date(timestampdate)
    
    let dataTable = new Array
    props.allDocumentsNonAcquitte.length!=0 && props.allDocumentsNonAcquitte.map((value,ind)=>{
        let obj = {
            bar: value.bar,
            createdAt: <Moment format="DD/MM/YYYY">{value.date_finalisation}</Moment>,
            client: value.client_infos.name+' '+value.client_infos.firstname,
            amount: <FormatedPrice langRegion={'fr-FR'} devise={props.devise} digit={props.digit} priceToFormat={value.ttc} />,
            id: value.id,
            action: 
                <span className="d-flex justify-content-between">
                    <ProfilActionButton path={'/invoice/details/'+value.id} />
                </span>,
            index: ind + 1,
        }
        dataTable.push(obj)
    })

    const headerSortingStyle = { backgroundColor: '#c8e6c9' };

    let columns = [
        {
            dataField: "action",
            text: "Actions",
            editable: false,
            headerClasses: 'head-action-row',
            classes: 'body-action-row',
        },
        {
            dataField: "masque",
            text: '',
            editable: false,
            formatter: (rowContent, row) => {
                return (    
                    <div style={{display:"block",width:"82px"}}></div>
                )
            },
            style: (cell, row, rowIndex, colIndex) => {
                return {
                    width:'99px',
                    backgroundColor:'white'
                };
            },
        },
        {
            dataField: "bar",
            text: t('referencePo'),
            editable:false,
            sort:true,
            headerSortingStyle
        },
        {
            dataField: "createdAt",
            text: t('createdAt'),
            editable:false,
            sort:true,
            headerSortingStyle
        },
        {
            dataField: "client",
            text: t('customer'),
            editable:false,
            sort:true,
            headerSortingStyle
        },
        {
            dataField: "amount",
            text: t('amountTtc'),
            editable:false,
            sort:true,
            headerSortingStyle
        },
    ];

    useEffect(function () {
        let columnCookie
        let arrCol = []
            
        if(cookies.dashboard_billing_table_reorganize){
            let newValueToIncludeInCookie
            let cookieLecture = cookies.dashboard_billing_table_reorganize.split(',')

            let already_done=[];
            cookieLecture.map(value=>{
                columns.map(item=>{
                    if(item.dataField==value){
                        arrCol.push(item)
                        already_done.push(value);
                    }
                })
            })
            columns.map(item=>{
                if(!already_done.includes(item.dataField)){ 
                    //si une nouvelle à été ajouté aux customsField, elle n'est pas ajoutée desuite au cookie de reorganisation si celui ci existe
                    //dans le cas où il n'existerait pas, on va créer un nouveau cookie afin de l'inclure pour ne pas avoir d'erreur sur les export 
                    newValueToIncludeInCookie=true
                    arrCol.push(item);
                }
            })

            columnCookie = arrCol
            if(newValueToIncludeInCookie==true){ 
                let arr = []
                arrCol.map(value=>
                    arr.push(value.dataField)
                )
                setCookie('dashboard_billing_table_reorganize',arr.join(),{ path: "/", expires: expire_date })
            }
        }else{
            columnCookie = columns
        } 
        setColumnOrganize(columnCookie)
    }, [])

    const sizePerPageOptionRenderer = ({
        text,
        page,
        onSizePerPageChange
    }) => (
        <li
            key={ text }
            role="presentation"
            className="dropdown-item"
            onMouseDown={ (e) => {
                e.preventDefault();
                onSizePerPageChange(page);
                setPerPage(page)
            } }
        >
            <a
                href="#"
                tabIndex="-1"
                role="menuitem"
                data-page={ page }
                style={ { color: 'black' } }
            >
                { text }
            </a>
        </li>
    );

    let paginationOptions = {
        paginationSize: 5,
        pageStartIndex: 1,
        firstPageText: t('tableFirst'),
        prePageText: t('tableBack'),
        nextPageText: t('tableNext'),
        lastPageText: t('tableLast'),
        nextPageTitle: t('tableNextTitle'),
        prePageTitle: t('tableBackTitle'),
        firstPageTitle: t('tableFistTitle'),
        lastPageTitle: t('tableLastTitle'),
        sizePerPageOptionRenderer,
        sizePerPage: perPage,
        showTotal: true,
        totalSize: dataTable.length,
    };

    return (
        <div>
            {(props.reloadData) ?
                <div className="spinner-border spinner-border-sm"></div>
            :
                columnOrganize.length!=0 &&
                    <small>
                        <ToolkitProvider
                            keyField="index"
                            data={dataTable}
                            columns={columnOrganize}
                            search
                        >
                        {(propst) => (
                            <>
                                <div className="d-flex justify-content-between mb-3">
                                    <div>
                                        <ColumDataTableReorganize 
                                            columnOrganize={columnOrganize}
                                            setColumnOrganize={setColumnOrganize}
                                            setCookie={setCookie}
                                            cookieName='dashboard_billing_table_reorganize'
                                            showColumnOrganize={showColumnOrganize}
                                            setShowColumnOrganize={setShowColumnOrganize}
                                            btnSwitch={false}//pour affiche ou non le btn swith
                                            initSwith={true}//initialier à false il affichera par défaut le toggle des colonnes, sur true il affichera le drag and drop pour changer la position des colonnes
                                        />
                                    </div>
                                    <div className="d-flex justify-content-end align-items-center">
                                        <span className="mb-2 me-1">{t('tableSearch')}:</span>
                                        <SearchBar {...propst.searchProps} className="mb-2" />
                                    </div>
                                </div>
                                <div className="position-relative">
                                    <CustomDataTable 
                                        propst={propst}
                                        paginationOptions={paginationOptions}
                                        columns={columns}
                                        defaultSortDirection="asc"
                                    />
                                </div>
                            </>
                        )}
                        </ToolkitProvider>
                    </small>
            }
        </div>
    )
};

export default FactureNonAcquitteList;