import React, { useState,useEffect,useContext } from 'react';
import { useTranslate } from 'react-redux-multilingual'
import { CardPerso, Loading } from "@gull";
import { IsAuthContext } from 'app/App';
import axios from 'axios';
import swal from "sweetalert2";
import ChiffreAffairesChart from './ChiffreAffairesComp/ChiffreAffairesChart'

const useLoad = (authParam) =>{
    const t = useTranslate();
    const [loading,setLoading]=useState(true)
    const [caTtc,setCaTtc] = useState([]) 
    const [caHt,setCaHt] = useState([]) 
    const [months,setMonths] = useState([])
    const [filtreYears,setFiltreYears] = useState([])
    const [company,setCompany]=useState({}) 
    const [reloadData,setReloadData]=useState(false) 
    const [relance,setRelance]=useState(Date.now()) 

    let mois = {
        1:t('january'),
        2:t('february'),
        3:t('march'),
        4:t('april'),
        5:t('may'),
        6:t('june'),
        7:t('july'),
        8:t('august'),
        9:t('september'),
        10:t('october'),
        11:t('november'),
        12:t('december'),
    }

    let today = new Date();
    let currentYear = today.getFullYear();

    const [paramUrl,setParamUrl]=useState({
        year:currentYear,
        company:''
    }) 

    useEffect(function () {
        (async function() {
            const response = await axios.get(process.env.REACT_APP_API_URL+"api/company/"+authParam.company.id
            ).then((response) => {
                setCompany(response.data)
            }).catch((error) => {
                if(error=='end_session') return;
                let errorName = (error.response==undefined) ? 'unknowError' : (error.response.status==403) ? 'forbiddenGeneral' : 'unknowError'
                swal.fire(t(errorName), "", "error");
            })
        })()
    }, [])
    
    useEffect(function () {
        (async function() {
            setReloadData(true)
            const response = await axios.get(process.env.REACT_APP_API_URL+"api/facture/report/year/"+paramUrl.year+paramUrl.company
            ).then((response) => {
                if(!response.data.valid){
                    let interms='';
                    var keys = Object.keys(response.data.errors);
                    for(var i = 0; i < keys.length; i++){
                        interms+=response.data.errors[keys[i]].join(',')+','
                    }
                    interms=interms.split(',')
                    let messageError='';
                    interms.forEach(interm=>messageError+=t(interm)+'. ')
                    messageError=messageError.replace('undefined','');
                    swal.fire(messageError, "", "error");
                }
                else{   
                    //ici on crée un tableau avec toutes les années figurant entre l'année de création de la compagnie sur freebird et l'année en cours
                    let arrYears = []
                    for(var year = response.data.first_year; year <= currentYear; year++){
                        arrYears.push(year)
                    }
                    setFiltreYears(arrYears)
                    
                    //ici on crée un tableau qui retourne les mois dans l'ordre du début d'exercice jusq'au dernier mois d'exercice
                    let arr = []
                    response.data.mois.map(month=>{
                        arr.push(mois[month])
                    })
                    setMonths(arr)

                    //ici on crée duex tableau, un pour le cattc et un poiur le caht
                    let arrTtc = []
                    let arrHt = []
                    response.data.datas.map(value=>{
                        arrTtc.push(value.total_ttc)
                        arrHt.push(value.total_ht)
                    })
                    setCaTtc(arrTtc)
                    setCaHt(arrHt)
                    
                    setLoading(false)
                    setReloadData(false)
                }
            }).catch((error) => {
                if(error=='end_session') return;
                let errorName = (error.response==undefined) ? 'unknowError' : (error.response.status==403) ? 'forbiddenGeneral' : 'unknowError'
                swal.fire(t(errorName), "", "error");
            })
            setLoading(false)
            setReloadData(false)
        })()
    }, [relance])

    return [loading,caTtc,caHt,months,filtreYears,paramUrl,setParamUrl,company,reloadData,setRelance]
}

const ChiffreAffaires = () =>{
    const t = useTranslate();
    const { authParam } = useContext(IsAuthContext);
    const [loading,caTtc,caHt,months,filtreYears,paramUrl,setParamUrl,company,reloadData,setRelance]=useLoad(authParam)

    const handleChange = (e,name) =>{
        setParamUrl({...paramUrl,[name]:e.target.value})
    }
    
    if(loading){
        return(<Loading></Loading>)
    }

    return (
        <div>
            <div className="row mb-3">
                <div className="col-12 col-md-5 form-group">
                    <label>{t('company')}</label>
                    <select disabled={reloadData} value={paramUrl.company} className='form-control' onChange={(e)=>handleChange(e,'company')}>
                        <option value={''}>{company.name}</option>                            
                        {Object.keys(company).length !=0 && company.other_company.length != 0 &&
                            company.other_company.map(value=>{
                                return(
                                    <option key={value.id} value={'/'+value.id}>{value.name}</option>
                                )
                            })
                        }
                    </select>
                </div>
                <div className="col-12 col-md-5 form-group">
                    <label>{t('yearOfExercise')}</label>
                    <select disabled={reloadData} defaultValue={paramUrl.year} className='form-control' onChange={(e)=>handleChange(e,'year')}>
                        {filtreYears.map(year=>{
                            return(
                                <option key={year} value={year}>{year}</option>
                            )
                        })}
                    </select>
                </div>
                <div className='col'>
                    <button disabled={reloadData} className="btn btn-primary mt-3" onClick={()=>setRelance(Date.now())}>
                        {reloadData ? <span><span>&nbsp;</span><div className="spinner-border spinner-border-sm"></div></span> : t('btnValidate')}
                    </button>
                </div>
            </div>
            <CardPerso header={t('administrationTurnoverPos')+' '+paramUrl.year}>
                {reloadData ? 
                    <span className='d-flex justify-content-center'><div className="spinner-border spinner-border-sm"></div></span>
                :
                    <ChiffreAffairesChart caTtc={caTtc} caHt={caHt} months={months} paramUrl={paramUrl}/>
                }
            </CardPerso>
        </div>
    )
};

export default ChiffreAffaires;