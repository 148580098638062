import React, { useState } from 'react';
import { useTranslate } from 'react-redux-multilingual'
import axios from 'axios';
import swal from "sweetalert2";
import AlertDocument from '../DetailDocumentComp/AlertDocument';
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBell } from '@fortawesome/free-solid-svg-icons'
import {  Link } from "react-router-dom";

const ConvertButton = (props) =>{
    const t = useTranslate();
    const [validButtonDelete,setValidButtonDelete]=useState(false)

    return(
        <button 
            disabled={validButtonDelete} 
            className="btn btn-danger" 
            onClick={()=>props.submitReminder(props.id,setValidButtonDelete)}
        >
            {(validButtonDelete) ? <div className="spinner-border spinner-border-sm"></div> : t('cancelReminder')}
        </button>
    )
}

const RappelList = (props) =>{
    const t = useTranslate();
    const [validButton,setValidButton]=useState(false)
  
    const [showAlertList,setShowAlertList]=useState(false) 
    const [errors,setErrors]=useState(false) 

    const submitReminder = async (id,setValidButtonDelete) =>{
        setValidButtonDelete(true)
        let obj = {}

        const response = await axios.post(process.env.REACT_APP_API_URL+"api/facture/alert/create_or_update/"+id, obj
        ).then((response) => {
            if(!response.data.valid){
                let interms='';
                var keys = Object.keys(response.data.errors);
                for(var i = 0; i < keys.length; i++){
                    interms+=response.data.errors[keys[i]].join(',')+','
                }
                interms=interms.split(',')
                let messageError='';
                interms.forEach(interm=>messageError+=t(interm)+'. ')
                messageError=messageError.replace('undefined','');
                swal.fire(messageError, "", "error")
            }
            else{   
                let newData = props.allAlert.filter(value=>value.id!=id)
                props.setAllAlert(newData)
            }
        }).catch((error) => {
            if(error=='end_session') return;
            swal.fire(t('unknowError'), "", "error");
        })
        setValidButtonDelete(false)
    }

    let reminderInfo = (showAlertList) ? props.allAlert.find(value=>value.id==showAlertList) : false

    return (
        <div className="col-12">
            <Modal size="md" show={showAlertList} onHide={()=>setShowAlertList(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('reminders')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AlertDocument
                        id={showAlertList} 
                        setValidButton={setValidButton} 
                        validButton={validButton} 
                        errors={errors} 
                        setErrors={setErrors} 
                        allInfos={reminderInfo} 
                        setAllInfos={props.setAllAlert}
                        allAlert={props.allAlert}
                        from='dashboard'
                    /> 
                </Modal.Body>
            </Modal>
            <div className='alert alert-info'>
                <div className='mb-2'><span className='fs-5'><FontAwesomeIcon icon={faBell} /></span>&nbsp;<span>{t('reminderMsg')}</span></div>
                {props.allAlert.map(value=>{
                    return(
                        <div key={value.id} className="card mb-1">
                            <div className="card-body">
                                <h5><Link to={"/invoice/details/"+value.id}className="lien-to">{value.bar}</Link></h5>
                                {value.alert_texte!=null ? <p className='text-muted' dangerouslySetInnerHTML={{ __html: value.alert_texte}}></p> : false}
                                <button className="btn btn-primary" onClick={()=>setShowAlertList(value.id)}>{t('btnModifier')}</button>&nbsp;&nbsp;
                                <ConvertButton
                                    id={value.id}
                                    submitReminder={submitReminder}
                                />
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
};

export default RappelList;