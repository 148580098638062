import { CategoryInventoryScan } from "@gull";
import { lazy, useContext } from "react";
import DashboardBilling from "./DashboardBilling";
import ChiffreAffaires from "./ChiffreAffaires";
const CreateBilling = lazy(() => import("./CreateBilling"));
const SearchBilling = lazy(() => import("./SearchBilling"));
const EndBilling = lazy(() => import("./EndBilling"));
const TemplateBilling = lazy(() => import("./TemplateBilling"));
const TemplateEmail = lazy(() => import("./TemplateEmail"));
const RecurrenceBilling = lazy(() => import("./RecurrenceBilling"));
const NotificationListErp = lazy(() => import("./Notification/NotificationListErp"));
const NotificationDetailErp = lazy(() => import("./Notification/NotificationDetailErp"));
const HistoriqueDetail = lazy(() => import("./Historique/HistoriqueDetail"));
const DetailDocument = lazy(() => import("./DetailDocument"));
const FacturationReport = lazy (()=> import("./ReportPbi/FacturationReport"))

const facturationRoutes = [
    {
        path: "/invoice/dashboard",
        component: DashboardBilling
    },
    {
        path: "/invoice/search",
        component: SearchBilling
    },
    {
        path: "/invoice/invoice/create/:id",
        component: CreateBilling
    },
    {
        path: "/invoice/invoice/end/:id",
        component: EndBilling
    },
    {
        path: "/invoice/invoice/recurrence",
        component: RecurrenceBilling
    },
    {
        path: "/invoice/invoice/template",
        component: TemplateBilling
    },
    {
        path: "/invoice/email/template",
        component: TemplateEmail
    },
    {
        path: "/invoice/notifications/list",
        component: NotificationListErp
    },
    {
        path: "/invoice/notifications/detail/:id",
        component: NotificationDetailErp
    },
    {
        path: "/invoice/historique/edit/:id",
        component: HistoriqueDetail
    },
    {
        path: "/invoice/details/:id",
        component: DetailDocument
    },
    {
        path: "/invoice/chiffre_affaires",
        component: ChiffreAffaires
    },
    {
        path: "/invoice/report",
        component: FacturationReport
    },
  ];
  
  export default facturationRoutes; 