import React, {useState, useEffect,useContext} from 'react';
import {ControlAccess} from "@gull";
import { useForm } from "react-hook-form";
import { useTranslate } from 'react-redux-multilingual';
import swal from "sweetalert2";
import axios from 'axios';
import { IsAuthContext } from 'app/App';
import SelectRh from './AdjustmentStockComp/SelectRh';
import AdjustmentStockForm from './AdjustmentStockComp/AdjustmentStockForm'

const useLoad = (articleid) => {
    const t = useTranslate();
    const [apiError,setApiError]=useState(false)
    const [shopWithItems,setShopWithItems]=useState({})

    useEffect(function () {
        (async function() {
            const response = await axios.get(process.env.REACT_APP_API_URL+"api/erp/article/large_stock/"+articleid
            ).then((response) => {
                if(!response.data.valid){
                    let interms='';
                    var keys = Object.keys(response.data.errors);
                    for(var i = 0; i < keys.length; i++){
                        interms+=response.data.errors[keys[i]].join(',')+','
                    }
                    interms=interms.split(',')
                    let messageError='';
                    interms.forEach((interm)=>{
                        let finalMessage=interm.split('/!');
                        if(finalMessage.length==2){
                            messageError+=t(finalMessage[0],{name:finalMessage[1]})+'<br/>'
                        }
                        else{
                            messageError+=t(interm)+'<br/>'
                        }
                    })
                    messageError=messageError.replace('undefined','');
                    setApiError(messageError)

                }else{
                    setShopWithItems(response.data.by_section)
                }
            }).catch((error) => {
                if(error=='end_session') return;
                swal.fire(t('unknowError'), "", "error");
            })
        })()
    }, [])

    return[shopWithItems,apiError]
}

const AdjustmentStockModal = (props) => {
    const t = useTranslate();
    const { authParam } = useContext(IsAuthContext);
    const [shopWithItems,apiError]=useLoad(props.articleid)
    const { register, formState: { errors }, handleSubmit, setValue, reset, getValues} = useForm();
    const [validButton,setValidButton] = useState(false)
    const [error,setError] = useState(false)
    const [success,setSuccess] = useState(false)
    const [cashierId,setCashierId]=useState(false) 
    const [isRestricted,setIsRestricted]=useState(false) 
    const [valueInputCashier,setValueInputCashier]=useState('') 

    const modulePos = authParam.modules.find(value=>value.name=='pos')

    const [qteToMove,setQteToMove]=useState('')

    const [field,setField]=useState({
        action:false,
        section:false,
        zone:false,
        bins:false,
        quantite:false,
        memo:false,
        button:true
    })
    const [loading,setLoading]=useState({
        zone:false,
        bins:false
    })
    const [data,setData]=useState({
        zone:{},
        zoneContainArticle:{},
        bins:{},
        binsAll:[]
    })

    let shopsList = Object.entries(shopWithItems).filter(([shopName,value])=> +value.total >= 0) //on récupère uniquement les sections ayant l'article en magasin

    //on récupère tous les ids des zones de stock qui ont des bins 
    let hasBins = []
    if(Object.keys(data.zone).length!=0){
        let arr = Object.entries(data.zone).filter(([key,value])=>value.havebins==1)
        for(let i=0; i<arr.length; i++){
            hasBins.push(arr[i][1].zoneid)
        }
    }    

    const handleChange = (e,name) =>{
        switch (name) {
            case 'action':
                if(props.from=='pos'){ 
                    //si on effectue cette action depuis le pos, le champ section se remplie automatique avec le nom de notre commerce
                    //afin d'effectuer un ajustement uniquement sur notre commerce
                    setValue('section', props.posid)
                    setField({
                        ...field,
                        action:e.target.value, //dès que l'action est renseignée on affiche le champ section
                        section:props.posid,//dès que la section est renseignée on affiche le champ zone
                        zone:false, //si on revient sur le choix de l'action, tout le formulaire est remis à zéro
                        bins:false,
                        quantite:false,
                        memo:false,
                        button:true
                    })
                    getData("api/erp/article/stock_in_section/"+props.articleid+'/'+props.posid,'zone') //on récupère tout les zones de stockages de la section 
                }else{
                    setField({ 
                        ...field,
                        action:e.target.value, //dès que l'action est renseignée on affiche le champ section
                        section:false,
                        zone:false, //si on revient sur le choix de l'action, tout le formulaire est remis à zéro
                        bins:false,
                        quantite:false,
                        memo:false,
                        button:true
                    })
                    setValue('section','')
                }
                setValue('memo','')
                setValue('zone','')
                setValue('bins','')
                setValue('quantite','')
            break;
            case 'section':
                setField({
                    ...field,
                    section:e.target.value,//dès que la section est renseignée on affiche le champ zone
                    zone:false,
                    bins:false,
                    quantite:false,
                    memo:false,
                    button:true
                })
                setValue('zone','')
                setValue('bins','')
                setValue('quantite','')
                setValue('memo','')
                if(e.target.value!='') getData("api/erp/article/stock_in_section/"+props.articleid+'/'+e.target.value,'zone') //on récupère tout les zones de stockages de la section 
            break;
            case 'zone':  
                if(hasBins.includes(+e.target.value)){
                    setField({
                        ...field,
                        zone:e.target.value,
                        quantite:false,
                        memo:false,
                        button:true
                    })//si la zone contient des bins on affichera le champ bins 
                    if(field.action=='add'){
                        getData("api/erp/bins/getall/"+e.target.value,'bins') //si l'action ajouter est choisie, on affiche toutes les bins de la zone de stockage
                    }else{
                        getData("api/erp/article/stock_in_zone/"+props.articleid+'/'+e.target.value,'bins')//sinon si l'action retirer est choisie, on affiche uniquement les bins qui contien l'article 
                    }
                }else{
                    setField({
                        ...field,
                        zone:false,
                        quantite:true,
                        memo:false,
                        button:true
                    }) //si il n'y a pas de bins, on affiche le champ quantite 
                    setData({
                        ...data,
                        bins:{},
                        binsAll:[]
                    })
                }
                setValue('bins','')
                setQteToMove('') 
                setValue('quantite','')
                setValue('memo','')
            break;
            case 'bins':
                setField({
                    ...field,
                    bins:e.target.value,
                    quantite:true
                })
            break;
            case 'quantite':
                setQteToMove(e.target.value=e.target.value.replace(/\D/g,''))
                setField({
                    ...field,
                    memo:true
                })
            break;
            case 'memo':
                if(e.target.value.length>0){
                    setField({...field,button:false})//dès que le memo est renseignée, le button valider est activé 
                }else{
                    setField({...field,button:true})//sinon il est désactivé 
                }
            break;
        }
    }

    const getData = async (apiPath,name) =>{
        switch (name) {
            case 'zone':
                setLoading({...loading,zone:true})
                break;
            case 'bins':
                setLoading({...loading,bins:true})
                break;
            }
        const response = await axios.get(process.env.REACT_APP_API_URL+apiPath
        ).then((response) => {
            if(!response.data.valid){
                let interms='';
                var keys = Object.keys(response.data.errors);
                for(var i = 0; i < keys.length; i++){
                    interms+=response.data.errors[keys[i]].join(',')+','
                }
                interms=interms.split(',')
                let messageError='';
                interms.forEach((interm)=>{
                    let finalMessage=interm.split('/!');
                    if(finalMessage.length==2){
                        messageError+=t(finalMessage[0],{name:finalMessage[1]})+'<br/>'
                    }
                    else{
                        messageError+=t(interm)+'<br/>'
                    }
                })
                messageError=messageError.replace('undefined','');
                setError(messageError)
            }else{
                switch (name) {
                    case 'zone':
                        let zoneWithArticle = Object.entries(response.data.by_zone).filter(([zoneName,value])=>value.total!=0)
                        let obj={}
                        for(let i=0; i<zoneWithArticle.length; i++){
                            obj[zoneWithArticle[i][0]]=zoneWithArticle[i][1]
                        }
                        setData({
                            ...data,
                            zone:response.data.by_zone,
                            zoneContainArticle:obj
                        })                        
                        setLoading({...loading,zone:false})
                        break;
                    case 'bins':
                        if(field.action=='add'){
                            setData({...data,binsAll:response.data.datas})
                        }else{
                            setData({...data,bins:response.data.by_bins})
                        } 
                        setLoading({...loading,bins:false})                       
                        break;
                    }
            }
        }).catch((error) => {
            if(error=='end_session') return;
            swal.fire(t('unknowError'), "", "error");
        })
        switch (name) {
            case 'zone':
                setLoading({...loading,zone:false})
                break;
            case 'bins':
                setLoading({...loading,bins:false})
                break;
            }
    }

    const onSubmit = async (form) => {
        setValidButton(true)
        setError(false)
        setSuccess(false)

        let obj={
            action:form.action,
            section:form.section,
            zone:form.zone,
            quantite:form.quantite,
            memo:(form.memo!='') ? form.memo : null,
            isFor:props.from,
            rh:(!form.rh) ? null : cashierId
        }
        if(Object.keys(data.bins).length!=0 || Object.keys(data.binsAll).length!=0){
            obj['bins']=field.bins
        }

        const callapi  = await axios.post(process.env.REACT_APP_API_URL+"api/erp/article/stock_ajust/"+props.articleid, obj)
            .then((response) => {
                if(!response.data.valid){
                    let interms='';
                    var keys = Object.keys(response.data.errors);
                    for(var i = 0; i < keys.length; i++){
                        interms+=response.data.errors[keys[i]].join(',')+','
                    }
                    interms=interms.split(',')
                    let messageError='';
                    interms.forEach((interm)=>{
                        let finalMessage=interm.split('/!');
                        if(finalMessage.length==2){
                            messageError+=t(finalMessage[0],{name:finalMessage[1]})+'<br/>'
                        }
                        else{
                            messageError+=t(interm)+'<br/>'
                        }
                    })
                    messageError=messageError.replace('undefined','');
                    setError(messageError)
                }
                else{
                    setSuccess(true)
                    props.setReadjustReload(Date.now())
                    setField({ 
                        ...field,
                        action:false,
                        section:false,
                        zone:false,
                        bins:false,
                        quantite:false,
                        memo:false,
                        button:true
                    })
                    setValue('action','')
                    setValue('section','')
                    setValue('zone','')
                    setValue('bins','')
                    setValue('quantite','')
                    setValue('memo','')
                    setValueInputCashier('')
                    setCashierId(false)
                }
            }).catch((error) => {
                if(error=='end_session') return;
                let errorName = (error.response==undefined) ? 'unknowError' : (error.response.status==403) ? 'forbiddenGeneral' : 'unknowError'
                swal.fire(t(errorName), "", "error");
            }
        )
        setValidButton(false)
        setTimeout(()=>{
            setSuccess(false);
        },1000)
    }

    const addActionRecord = async () =>{
        const callapi  = await axios.post(process.env.REACT_APP_API_URL+"api/erp/failed_stock_adjustment/create",{
            article_id:props.articleid,
            zone_id:getValues('zone'),
            rh_id:cashierId,
            quantite:getValues('quantite'),
            memo:getValues('memo')
        }).then((response) => {
            if(!response.data.valid){
                let interms='';
                var keys = Object.keys(response.data.errors);
                for(var i = 0; i < keys.length; i++){
                    interms+=response.data.errors[keys[i]].join(',')+','
                }
                interms=interms.split(',')
                let messageError='';
                interms.forEach((interm)=>{
                    let finalMessage=interm.split('/!');
                    if(finalMessage.length==2){
                        messageError+=t(finalMessage[0],{name:finalMessage[1]})+'<br/>'
                    }
                    else{
                        messageError+=t(interm)+'<br/>'
                    }
                })
                messageError=messageError.replace('undefined','');
                setError(messageError)
            }
            else{
                setIsRestricted(response.data.data.id)
            }
        }).catch((error) => {
            if(error=='end_session') return;
            let errorName = (error.response==undefined) ? 'unknowError' : (error.response.status==403) ? 'forbiddenGeneral' : 'unknowError'
            swal.fire(t(errorName), "", "error");
        })
    }
    
    const deleteActionRecord = async () =>{
        const callapi  = await axios.delete(process.env.REACT_APP_API_URL+"api/erp/failed_stock_adjustment/delete/"+isRestricted
        ).then((response) => {
            if(!response.data.valid){
                let interms='';
                var keys = Object.keys(response.data.errors);
                for(var i = 0; i < keys.length; i++){
                    interms+=response.data.errors[keys[i]].join(',')+','
                }
                interms=interms.split(',')
                let messageError='';
                interms.forEach((interm)=>{
                    let finalMessage=interm.split('/!');
                    if(finalMessage.length==2){
                        messageError+=t(finalMessage[0],{name:finalMessage[1]})+'<br/>'
                    }
                    else{
                        messageError+=t(interm)+'<br/>'
                    }
                })
                messageError=messageError.replace('undefined','');
                setError(messageError)
            }
            else{
                setIsRestricted(false)
                props.setRestriction({...props.restriction,go:false})

                setTimeout(()=>{
                    onSubmit(getValues())
                },500)
            }
        }).catch((error) => {
            if(error=='end_session') return;
            let errorName = (error.response==undefined) ? 'unknowError' : (error.response.status==403) ? 'forbiddenGeneral' : 'unknowError'
            swal.fire(t(errorName), "", "error");
        })
    }
    
    const controlBeforeSubmit = async () =>{
        setValidButton(true)
        if(props.restriction && !props.restriction.go){
            addActionRecord()
        }else{
            onSubmit(getValues())
        }
    }

    const callback = () =>{
        deleteActionRecord()
    }
    
    return(<>
        {(props.from=='pos' && modulePos.params.staff.active) ? <SelectRh section={props.section} disabled={isRestricted} setCashierId={setCashierId} valueInputCashier={valueInputCashier} setValueInputCashier={setValueInputCashier}/> : false}
        {(props.from=='erp') ?
            <AdjustmentStockForm 
                field={field}
                setField={setField}
                data={data}
                validButton={validButton}
                controlBeforeSubmit={controlBeforeSubmit}
                apiError={apiError}
                error={error}
                success={success}
                handleChange={handleChange}
                register={register}
                isRestricted={isRestricted}
                loading={loading}
                shopWithItems={shopWithItems}
                shopsList={shopsList}
                from={props.from}
                qteToMove={qteToMove}
            />
        :
            ( !modulePos.params.staff.active || cashierId) ?
                <AdjustmentStockForm 
                    field={field}
                    setField={setField}
                    data={data}
                    validButton={validButton}
                    controlBeforeSubmit={controlBeforeSubmit}
                    apiError={apiError}
                    error={error}
                    success={success}
                    handleChange={handleChange}
                    register={register}
                    isRestricted={isRestricted}
                    loading={loading}
                    shopWithItems={shopWithItems}
                    shopsList={shopsList}
                    from={props.from}
                    qteToMove={qteToMove}
                />
            :false
        }
        {(isRestricted) ? 
            <div className="row d-flex justify-content-center">
                <div className="col-8 mt-5">
                    <ControlAccess section={props.section} focus={true} restriction={props.restriction} setRestriction={props.setRestriction} moduleId={props.posid} callback={callback}/>
                </div>
            </div>
        :
           false
        }
        </>
    )
}

export default AdjustmentStockModal;
