import React, {useState} from "react";
import { useTranslate } from 'react-redux-multilingual'
import { CardPerso,ObjectifSectionChart,ControlAccess, ClientForm } from "@gull";

const ObjectifIndividuel = (props) =>{
    return(
        <CardPerso header={props.header}>
            <div className="row">
                <div className="col-4">
                    <ObjectifSectionChart title="CA TTC" isFor="cattc" tabFilter={props.tabFilter} data={props.data} real={props.real}/>
                </div>
                <div className="col-4">
                    <ObjectifSectionChart title="PM TTC" isFor="pmttc" tabFilter={props.tabFilter} data={props.data} real={props.real}/>
                </div>
                <div className="col-4">
                    <ObjectifSectionChart title="IV" isFor="iv" tabFilter={props.tabFilter} data={props.data} real={props.real}/>
                </div>
            </div>
        </CardPerso>
    )
}

const RhGoalsDayChart = (props) => {
    const t = useTranslate()
    const [individualRhIdGoals,setIndividualRhIdGoals]=useState(false) 
    const [managerIdGoals,setManagerIdGoals]=useState(false) 
    const [showBtnBack,setShowBtnBack]=useState(false) 

    const closeGoals = () =>{
        setIndividualRhIdGoals(false)
        setShowBtnBack(false)
        props.setRestrictionGoals({...props.restrictionGoals,go:false})
    }

    return (<>
        {(props.from && props.from=='pos') ?
            (props.restrictionGoals.go || individualRhIdGoals) ?
                <>
                    {(showBtnBack) &&
                        <div className="d-flex justify-content-end">
                            <button className="btn btn-danger mb-3" onClick={()=>closeGoals()}>{t('btnClose')}</button>
                        </div>
                    }
                    {(props.isfor=='period' && props.data.rhgoals==false) ?
                        Object.entries(props.realRh).map(([key,value],index)=>{
                            if(individualRhIdGoals){
                                if(individualRhIdGoals == key){ //Vu d'un vendeur dans le cas d'une restriction sur la vu des objectifs, il ne voit que ses objectifs et ventes
                                    return(
                                        <ObjectifIndividuel key={index} header={value[props.isfor].rh.name+' '+value[props.isfor].rh.firstname} real={value[props.isfor]} tabFilter='period'/>
                                    )
                                }
                            }else if(managerIdGoals){//Vu d'un responsable dans le cas d'une restriction sur la vu des objectifs, il voit ses chiffres et ceux des personnes qui ont vendu et qui ont des objectifs
                                if(managerIdGoals==key || value[props.isfor].cattc!=0 || value[props.isfor].pmttc!=0 || value[props.isfor].iv!=0){
                                    return(
                                        <ObjectifIndividuel key={index} header={value[props.isfor].rh.name+' '+value[props.isfor].rh.firstname} real={value[props.isfor]} tabFilter='period'/>
                                    )
                                }

                            }else{//Vu de l'ensemble des objectifs de tous les vendeurs qui ont des objectifs attibrués ou qui ont vendu quand il n'y a pas de restriction
                                if(value[props.isfor].cattc!=0 || value[props.isfor].pmttc!=0 || value[props.isfor].iv!=0){
                                    return(
                                        <ObjectifIndividuel key={index} header={value[props.isfor].rh.name+' '+value[props.isfor].rh.firstname} real={value[props.isfor]} tabFilter='period'/>
                                    )
                                }
                            }
                        })
                    :
                        props.data.rhgoals.map((value,index)=>{
                            if(individualRhIdGoals){//Vu d'un vendeur dans le cas d'une restriction sur la vu des objectifs, il ne voit que ses objectifs et ventes
                                if(individualRhIdGoals==value.rh_id && props.realRh[value.rh_id]){
                                    return(
                                        <ObjectifIndividuel key={index} header={value.rh.name+' '+value.rh.firstname} data={value} real={props.realRh[value.rh_id][props.isfor]} />
                                    )
                                }

                            }else if(managerIdGoals){//Vu d'un responsable dans le cas d'une restriction sur la vu des objectifs, il voit ses chiffres et ceux des personnes qui ont vendu et qui ont des objectifs
                                if(props.realRh[value.rh_id]){
                                    if(managerIdGoals==value.rh_id || value.cattc!=0 || value.pmttc!=0 || value.iv!=0 || props.realRh[value.rh_id][props.isfor].cattc!=0 || props.realRh[value.rh_id][props.isfor].pmttc!=0 || props.realRh[value.rh_id][props.isfor].iv!=0){
                                        return(
                                            <ObjectifIndividuel key={index} header={value.rh.name+' '+value.rh.firstname} data={value} real={props.realRh[value.rh_id][props.isfor]} />
                                        )
                                    }
                                }

                            }else{//Vu de l'ensemble des objectifs de tous les vendeurs qui ont des objectifs attibrués ou qui ont vendu quand il n'y a pas de restriction
                                if(props.realRh[value.rh_id]){
                                    if(value.cattc!=0 || value.pmttc!=0 || value.iv!=0 || props.realRh[value.rh_id][props.isfor].cattc!=0 || props.realRh[value.rh_id][props.isfor].pmttc!=0 || props.realRh[value.rh_id][props.isfor].iv!=0){
                                        return(
                                            <ObjectifIndividuel key={index} header={value.rh.name+' '+value.rh.firstname} data={value} real={props.realRh[value.rh_id][props.isfor]} />
                                        )
                                    }
                                }
                            }
                        })
                    }
                </>
            :
                <div>
                    <label htmlFor="consulter les objectifs" className='d-flex justify-content-center'>{t('reviewIndividualGoals')}</label>
                    <div style={{marginTop:'68px'}}>
                        <ControlAccess section={props.section} restriction={props.restrictionGoals} setRestriction={props.setRestrictionGoals} moduleId={props.sectionid} setIndividualRhIdGoals={setIndividualRhIdGoals} setManagerIdGoals={setManagerIdGoals} focus={false} setShowBtnBack={setShowBtnBack}/>
                    </div>
                </div>
        :
            <>
                {(props.isfor=='period' && props.data.rhgoals==false) ?
                    Object.entries(props.realRh).map(([key,value],index)=>{
                        // if(individualRhIdGoals){
                        //     if(individualRhIdGoals == key){  
                        //         return(
                        //             <ObjectifIndividuel key={index} header={value[props.isfor].rh.name+' '+value[props.isfor].rh.firstname} real={value[props.isfor]} tabFilter='period'/>
                        //         )
                        //     }
                        // }else{
                        //     return(
                        //         <ObjectifIndividuel key={index} header={value[props.isfor].rh.name+' '+value[props.isfor].rh.firstname} real={value[props.isfor]} tabFilter='period'/>
                        //     )
                        // }
                        if(value[props.isfor].cattc>0 || value[props.isfor].pmttc>0 || value[props.isfor].iv>0){
                            return(
                                <ObjectifIndividuel key={index} header={value[props.isfor].rh.name+' '+value[props.isfor].rh.firstname} real={value[props.isfor]} tabFilter='period'/>
                            )
                        }
                    })
                :
                    props.data.rhgoals.map((value,index)=>{
                        // if(individualRhIdGoals){
                        //     if(individualRhIdGoals==value.rh_id && props.realRh[value.rh_id]){
                        //         return(
                        //             <ObjectifIndividuel key={index} header={value.rh.name+' '+value.rh.firstname} data={value} real={props.realRh[value.rh_id][props.isfor]} />
                        //         )
                        //     }
                        // }else{
                        //     if(props.realRh[value.rh_id]){
                        //         return(
                        //             <ObjectifIndividuel key={index} header={value.rh.name+' '+value.rh.firstname} data={value} real={props.realRh[value.rh_id][props.isfor]} />
                        //         )
                        //     }
                        // }
                        if(props.realRh[value.rh_id]){
                            if(value.cattc!=0 || value.pmttc!=0 || value.iv!=0 || props.realRh[value.rh_id][props.isfor].cattc!=0 || props.realRh[value.rh_id][props.isfor].pmttc!=0 || props.realRh[value.rh_id][props.isfor].iv!=0){
                                return(
                                    <ObjectifIndividuel key={index} header={value.rh.name+' '+value.rh.firstname} data={value} real={props.realRh[value.rh_id][props.isfor]} />
                                )
                            }
                        }
                    })
                }
            </>
        }
    </>)
}

export default RhGoalsDayChart;