import React, { useState } from 'react';
import { useTranslate } from 'react-redux-multilingual'
import axios from 'axios';
import swal from "sweetalert2";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar } from '@fortawesome/free-solid-svg-icons'
import { useHistory } from "react-router-dom";

const ConvertButton = (props) =>{
    const t = useTranslate();
    const [validButton,setValidButton]=useState(false)

    return(
        <button 
            disabled={validButton} 
            className="btn btn-primary" 
            onClick={()=>props.convertToFacture(props.id,setValidButton)}
        >
            {(validButton) ? <div className="spinner-border spinner-border-sm"></div> : t('convertToInvoice')}
        </button>
    )
}

const NotConvertButton = (props) =>{
    const t = useTranslate();
    const [validButtonDelete,setValidButtonDelete]=useState(false)

    return(
        <button 
            disabled={validButtonDelete} 
            className="btn btn-warning" 
            onClick={()=>props.notConvertToFacture(props.id,props.recurrence,setValidButtonDelete)}
        >
            {(validButtonDelete) ? <div className="spinner-border spinner-border-sm"></div> : t('notConvertToInvoice')}
        </button>
    )
}

const RecurrenceList = (props) =>{
    const t = useTranslate();
    const history = useHistory();
    
    

    const convertToFacture = async (id,setValidButton) =>{
        setValidButton(true)
        const response = await axios.get(process.env.REACT_APP_API_URL+"api/facture/model/recup_model_recurrence/"+id
        ).then((response) => {
            if(!response.data.valid){
                let interms='';
                var keys = Object.keys(response.data.errors);
                for(var i = 0; i < keys.length; i++){
                    interms+=response.data.errors[keys[i]].join(',')+','
                }
                interms=interms.split(',')
                let messageError='';
                interms.forEach(interm=>messageError+=t(interm)+'. ')
                messageError=messageError.replace('undefined','');
                swal.fire(messageError, "", "error");

            }else{  
                history.push('/invoice/invoice/create/'+response.data.data.id);
            }
        }).catch((error) => {
            if(error=='end_session') return;
            let errorName = (error.response==undefined) ? 'unknowError' : (error.response.status==403) ? 'forbiddenGeneral' : 'unknowError'
            swal.fire(t(errorName), "", "error");

            
        })
        setValidButton(false)
    }

    const notConvertToFacture = async (modelId,recurrence,setValidButtonDelete) =>{
        let splitDayMonth = ''

        let splitDatetime = recurrence.split(' ');//je split ici car la date est retournée au format "2023-10-15 00:00:00" et je souhaite récupérer que la date 
        let daySplited = splitDatetime[0].split('-');//je resplit ici pour pouvoir récupérer individuellement le mois et le jour

        if(daySplited[2].startsWith('0')){
            splitDayMonth = daySplited[2].replace('0','')//si le jour est formaté '01' on retire le 0
        }else{
            splitDayMonth = daySplited[2]
        }
        
        setValidButtonDelete(true)
        const response = await axios.post(process.env.REACT_APP_API_URL+"api/facture/model/create_or_update_recurrence/"+modelId,{
            recurrence:splitDayMonth
        }).then((response) => {
            if(!response.data.valid){
                let interms='';
                var keys = Object.keys(response.data.errors);
                for(var i = 0; i < keys.length; i++){
                    interms+=response.data.errors[keys[i]].join(',')+','
                }
                interms=interms.split(',')
                let messageError='';
                interms.forEach(interm=>messageError+=t(interm)+'. ')
                messageError=messageError.replace('undefined','');
                swal.fire(messageError, "", "error");
            }else{   
                let newData = props.allRecurrence.filter(value=>value.id!=modelId)
                props.setAllRecurrence(newData)
            }
        }).catch((error) => {
            if(error=='end_session') return;
            let errorName = (error.response==undefined) ? 'unknowError' : (error.response.status==403) ? 'forbiddenGeneral' : 'unknowError'
            swal.fire(t(errorName), "", "error");
        })
        setValidButtonDelete(false)
    }
    
    return (
        <div className="col-12">
            <div className='alert alert-info'>
                <div className='mb-2'><span className='fs-5'><FontAwesomeIcon icon={faCalendar} /></span>&nbsp;<span>{t('recurrenceMsg')}</span></div>
                {props.allRecurrence.map((value,index)=>{
                    return(
                        <div key={value.id} className="card mb-1">
                            <div className="card-body">
                                <h5>{value.model}</h5>
                                {value.alert_texte!=null ? <p className='text-muted' dangerouslySetInnerHTML={{ __html: value.alert_texte}}></p> : false}
                                <ConvertButton
                                    convertToFacture={convertToFacture}
                                    id={value.id}
                                />&nbsp;&nbsp;
                                <NotConvertButton
                                    notConvertToFacture={notConvertToFacture}
                                    id={value.id}
                                    recurrence={value.recurrence}
                                />
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
};

export default RecurrenceList;