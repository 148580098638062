import React, { useContext } from "react";
import Chart from "react-apexcharts";
import { useTranslate } from 'react-redux-multilingual'
import { IsAuthContext } from 'app/App';
import {espace_mille} from 'app/espace_mille'

const ObjectifSectionChart = (props) => {
    const t = useTranslate()
    const { authParam } = useContext(IsAuthContext);

    let devise = authParam.company.devise;

    var options = {
        series: [
            {
                name: t('cattc'),
                data: props.caTtc
            }, 
            {
                name: t('caht'),
                data: props.caHt
            }, 
        ],
        chart: {
            type: 'line',
            height: 500,
        },
        stroke: {
            curve: 'straight'
        },
        dataLabels: {
            enabled: false,
        },
        xaxis: {
            categories: props.months,
        },
        yaxis: {
            labels: {
                show: true,
                formatter: function (val) {
                    return espace_mille(val)+' '+devise;
                }
            }
        },
        legend: {
            position: 'top',
            horizontalAlign: 'center'
        },
        tooltip: {
            followCursor: true,
            shared: true,
            y: {
                formatter: function (val) {
                    return espace_mille(val)+' '+devise
                }
            }
        },
        fill: {
            opacity: 1
        }
    };
    

    return (<div>
        <Chart
            options={options}
            series={options.series}
            type={options.chart.type}
            height={500}
        />
        </div>
    );
}

export default ObjectifSectionChart;
